import { useEffect } from "react";

function RedirectToPage() {

  useEffect(() => {
    window.location.href = "/SecurityHQ";
  }, []);

  return null;
}

export default RedirectToPage;
