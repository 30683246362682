/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
// import "assets/scss/paper-kit.scss";
import "assets/css/paper-kit.min.css";
import "assets/css/home-women.css";
import "assets/css/home-common.css";
import "assets/demo/demo.css";
import "assets/css/LineIcons.css";
import HomePage from "views/Home";
import Contact from "views/Contact";
import CookiePolicy from "views/CookiePolicy";
import Recent from "views/Recent";

import Privacy from "views/Privacy";
import Registeracc from "views/RegisterAcc";
import RegisterWebinar from "views/RegisterWebinar";
import Registercloud4c from "views/RegisterCloud4c";
import ReWriteSecurityHQ from "views/ReWriteSecurityHQ";
import RegisterInfosys from "views/RegisterInfosys";
import RegisterExotel from "viewExotel/Register";
import RegisterNewgen from "viewNewgen/Register";
import RegisterBranch from "viewBranch/Register";
import RegisterKhaleejAwards from "viewKhaleejAwards/Register";
import RegisterKhaleejAwards2025 from "viewKhaleejAwards2025/Register";

import ThankYouRegister from "./views/ThankYouRegister";
import Sponsor from "views/Sponsor";
import Reports from "views/Reports";
import News from "views/News";
import HomeUI from "viewsUAEIndia/home";
import Overview from "viewsUAEIndia/Overview";
import AgendaUI from "viewsUAEIndia/Agenda";
import RegisterUI from "viewsUAEIndia/Register";
import RegisterSponsorUI from "viewsUAEIndia/RegisterSponsor";
import RegisterBrochureUI from "viewsUAEIndia/RegisterBrochure";
import ThankYouRegisterUI from "./viewsUAEIndia/ThankYouRegister";
import Venue from "./viewsUAEIndia/Venue";
import NewsUI from "./viewsUAEIndia/News";

import HomeWomen from "viewsWomen/home.js";
import RegisterPageWomen from "viewsWomen/Register.js";
import Gallery from "viewsWomen/Gallery.js";
import SponsorWomen from "viewsWomen/Sponsor";
import SponsorSingleWomen from "viewsWomen/SponsorSingle";

import SpeakerWomen from "viewsWomen/SpeakerPage";
import PrivacyWomen from "viewsWomen/Privacy";
import ThankYouRegisterWomen from "./viewsWomen/ThankYouRegister";
import OverviewWomen from "viewsWomen/Overview";

// Section Freshworks Start

import HomeFreshworks from "viewsFreshworks/home.js";
import RegisterPageFreshworks from "viewsFreshworks/Register.js";

// Section Freshworks End

// Section InfosysNew Start

import HomeInfosysNew from "viewsInfosysNew/home.js";
import RegisterPageInfosysNew from "viewsInfosysNew/Register.js";

// Section InfosysNew End

import HomeCX from "viewsCX/home";
import OverviewCX from "viewsCX/Overview";
import AgendaCX from "viewsCX/Agenda";
import RegisterCX from "viewsCX/Register";
import RegisterAwardCX from "viewsCX/RegisterAwards";
import RegisterSponsorCX from "viewsCX/RegisterSponsor";
import RegisterBrochureCX from "viewsCX/RegisterBrochure";
import ThankYouRegisterCX from "./viewsCX/ThankYouRegister";
import VenueCX from "./viewsCX/Venue";
import NewsUICX from "./viewsCX/News";
import AwardCX from "./viewsCX/Awards";
import AboutCX from "./viewsCX/About";
import CXEvolveKSAEdition2023 from "./viewsCX/CXEvolveKSAEdition2023";
import GalleryCX from "./viewsCX/GalleryCX";
import DownloadCX from "./viewsCX/Download";
import PastSponsorCX from "./viewsCX/PastSponsors";

import HomeMeta from "viewsMeta/home";
import OverviewMeta from "viewsMeta/Overview";
import AgendaMeta from "viewsMeta/Agenda";
import RegisterMeta from "viewsMeta/Register";
import RegisterSponsorMeta from "viewsMeta/RegisterSponsor";
import RegisterBrochureMeta from "viewsMeta/RegisterBrochure";
import ThankYouRegisterMeta from "./viewsMeta/ThankYouRegister";
import VenueMeta from "./viewsMeta/Venue";
import NewsUIMeta from "./viewsMeta/News";
import AboutMeta from "./viewsMeta/About";
import SponsorSingleMeta from "viewsMeta/SponsorSingle";
import TermsCX from "viewsCX/terms";
import RefundCX from "viewsCX/refund";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact render={(props) => <HomePage {...props} />} />

      <Route path="/sponsor" exact render={(props) => <Sponsor {...props} />} />
      <Route path="/news" exact render={(props) => <News {...props} />} />
      <Route path="/contact-us" exact render={(props) => <Contact {...props} />} />
      <Route path="/cookie-policy" exact render={(props) => <CookiePolicy {...props} />} />
      <Route path="/recent" exact render={(props) => <Recent {...props} />} />
      <Route path="/post-show-reports" exact render={(props) => <Reports {...props} />} />

      {/* <Route path='/' exact render={(props) => <RegisterPage {...props} />} /> */}

      <Route
        path="/thank-you"
        exact
        render={(props) => <ThankYouRegister {...props} answer="" />}
      />
      <Route path="/accenture-exchange" exact render={(props) => <Registeracc {...props} />} />
      {/* <Route
        path="/cxo-executive-summit"
        exact
        render={(props) => <Registercloud4c {...props} />}
      /> */}
      <Route path="/SecurityHQ" exact render={(props) => <Registercloud4c {...props} />} />
      <Route path="/cxo-executive-summit" exact render={(props) => <ReWriteSecurityHQ {...props} />} />
      <Route path="/recomposing-banking" exact render={(props) => <RegisterInfosys {...props} />} />

      <Route path="/exotel-boardroom" exact render={(props) => <RegisterExotel {...props} />} />
      <Route path="/newgen-roundtable" exact render={(props) => <RegisterNewgen {...props} />} />
      <Route path="/branch-iftaar" exact render={(props) => <RegisterBranch {...props} />} />
      <Route
        path="/khaleejtimes-awards"
        exact
        render={(props) => <RegisterKhaleejAwards {...props} />}
      />
      <Route
        path="/khaleejtimes-awards-2025"
        exact
        render={(props) => <RegisterKhaleejAwards2025 {...props} />}
      />

      <Route path="/mbg-webinar" exact render={(props) => <RegisterWebinar {...props} />} />
      <Route path="/privacy-notice" exact render={(props) => <Privacy {...props} />} />
      <Route path="/uae-india-investment-forum" exact render={(props) => <HomeUI {...props} />} />

      <Route
        path="/uae-india-investment-forum/overview"
        exact
        render={(props) => <Overview {...props} />}
      />
      <Route
        path="/uae-india-investment-forum/overview/:type"
        exact
        render={(props) => <Overview {...props} />}
      />
      <Route
        path="/uae-india-investment-forum/register"
        exact
        render={(props) => <RegisterUI {...props} />}
      />
      <Route
        path="/uae-india-investment-forum/sponsor"
        exact
        render={(props) => <RegisterSponsorUI {...props} />}
      />
      <Route
        path="/uae-india-investment-forum/brochure"
        exact
        render={(props) => <RegisterBrochureUI {...props} />}
      />
      <Route
        path="/uae-india-investment-forum/agenda"
        exact
        render={(props) => <AgendaUI {...props} />}
      />
      <Route
        path="/uae-india-investment-forum/thank-you/:id"
        exact
        render={(props) => <ThankYouRegisterUI {...props} answer="" />}
      />
      <Route
        path="/uae-india-investment-forum/venue-travel"
        exact
        render={(props) => <Venue {...props} />}
      />
      <Route
        path="/uae-india-investment-forum/news"
        exact
        render={(props) => <NewsUI {...props} />}
      />

      <Route path="/cx-evolve2022" exact render={(props) => <HomeCX {...props} />} />
      <Route path="/cx-evolve" exact render={(props) => <HomeCX {...props} />} />

      <Route path="/cx-evolve/overview" exact render={(props) => <OverviewCX {...props} />} />
      <Route path="/cx-evolve/overview/:type" exact render={(props) => <OverviewCX {...props} />} />
      <Route path="/cx-evolve/register" exact render={(props) => <RegisterCX {...props} />} />
      <Route path="/cx-evolve/sponsor" exact render={(props) => <RegisterSponsorCX {...props} />} />
      <Route
        path="/cx-evolve/past-partners"
        exact
        render={(props) => <PastSponsorCX {...props} />}
      />
      <Route
        path="/cx-evolve/brochure"
        exact
        render={(props) => <RegisterBrochureCX {...props} />}
      />
      <Route path="/cx-evolve/agenda" exact render={(props) => <AgendaCX {...props} />} />
      <Route
        path="/cx-evolve/thank-you/:id"
        exact
        render={(props) => <ThankYouRegisterCX {...props} answer="" />}
      />
      <Route path="/cx-evolve/venue-travel" exact render={(props) => <VenueCX {...props} />} />
      <Route path="/cx-evolve/news" exact render={(props) => <NewsUICX {...props} />} />
      <Route path="/cx-evolve/about" exact render={(props) => <AboutCX {...props} />} />
      <Route
        path="/cx-evolve/KSA-Edition-2023"
        exact
        render={(props) => <CXEvolveKSAEdition2023 {...props} />}
      />
      <Route path="/cx-evolve/gallery" exact render={(props) => <GalleryCX {...props} />} />
      <Route path="/cx-evolve/download-psr" exact render={(props) => <DownloadCX {...props} />} />
      <Route path="/cx-evolve/recognition" exact render={(props) => <AwardCX {...props} />} />
      <Route
        path="/cx-evolve/register-awards"
        exact
        render={(props) => <RegisterAwardCX {...props} />}
      />
      <Route path="/cx-evolve/terms-conditions" exact render={(props) => <TermsCX {...props} />} />
      <Route
        path="/cx-evolve/cancellation-refunds"
        exact
        render={(props) => <RefundCX {...props} />}
      />

      <Route path="/women-directors" exact render={(props) => <HomeWomen {...props} />} />
      <Route
        path="/women-directors/sponsor"
        exact
        render={(props) => <SponsorWomen {...props} />}
      />
      <Route
        path="/women-directors/sponsor/:type"
        exact
        render={(props) => <SponsorWomen {...props} />}
      />
      <Route
        path="/women-directors/speakers"
        exact
        render={(props) => <SpeakerWomen {...props} />}
      />
      <Route
        path="/women-directors/overview/:type"
        exact
        render={(props) => <OverviewWomen {...props} />}
      />
      <Route
        path="/women-directors/register"
        exact
        render={(props) => <RegisterPageWomen {...props} />}
      />
      <Route path="/women-directors/gallery" exact render={(props) => <Gallery {...props} />} />
      <Route
        path="/women-directors/thank-you/:id"
        exact
        render={(props) => <ThankYouRegisterWomen {...props} answer="" />}
      />
      <Route
        path="/women-directors/privacy-policy"
        exact
        render={(props) => <PrivacyWomen {...props} />}
      />
      <Route
        path="/women-directors/sponsors/:sponsor"
        exact
        render={(props) => <SponsorSingleWomen {...props} />}
      />

      {/* Freshworks */}
      <Route path="/freshworks" exact render={(props) => <HomeFreshworks {...props} />} />
      <Route
        path="/freshworks/register"
        exact
        render={(props) => <RegisterPageFreshworks {...props} />}
      />

      {/* InfosysNew */}
      <Route path="/infosys" exact render={(props) => <HomeInfosysNew {...props} />} />
      <Route
        path="/infosys/register"
        exact
        render={(props) => <RegisterPageInfosysNew {...props} />}
      />

      {/* Meta */}
      <Route path="/meta-vision2022" exact render={(props) => <HomeMeta {...props} />} />

      <Route
        path="/meta-vision2022/overview"
        exact
        render={(props) => <OverviewMeta {...props} />}
      />
      <Route
        path="/meta-vision2022/overview/:type"
        exact
        render={(props) => <OverviewMeta {...props} />}
      />
      <Route
        path="/meta-vision2022/register"
        exact
        render={(props) => <RegisterMeta {...props} />}
      />
      <Route
        path="/meta-vision2022/sponsor"
        exact
        render={(props) => <RegisterSponsorMeta {...props} />}
      />
      <Route
        path="/meta-vision2022/brochure"
        exact
        render={(props) => <RegisterBrochureMeta {...props} />}
      />
      <Route path="/meta-vision2022/agenda" exact render={(props) => <AgendaMeta {...props} />} />
      <Route
        path="/meta-vision2022/thank-you/:id"
        exact
        render={(props) => <ThankYouRegisterMeta {...props} answer="" />}
      />
      <Route
        path="/meta-vision2022/venue-travel"
        exact
        render={(props) => <VenueMeta {...props} />}
      />
      <Route path="/meta-vision2022/news" exact render={(props) => <NewsUIMeta {...props} />} />
      <Route path="/meta-vision2022/about" exact render={(props) => <AboutMeta {...props} />} />
      <Route
        path="/meta-vision2022/sponsors/:sponsor"
        exact
        render={(props) => <SponsorSingleMeta {...props} />}
      />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
