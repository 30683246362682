export const speakersNew = [
  {
    name: 'Dr. Jassim Al Awadhi',
    title: 'Senior Director, Digital Transformation Principal',
    company: 'Du',
    image: 'jassim-al-awadhi.jpg',
    text: [``],
  },
  {
    name: 'Alok Uniyal',
    title: 'Senior Vice President & Head - Process Consulting Practice, Infosys',
    company: 'Infosys',
    image: 'alok-uniyal.jpg',
    text: [`Alok leads the IT Process Consulting Practice at Infosys. He is also driving the Agile & DevOps Transformation at Infosys. As a seasoned IT Professional with rich experience in IT Consulting & Transformation, Alok specializes in helping organizations embrace New Ways of Working, leveraging Lean, Agile, DevOps, and Design Thinking - toward greater Business Agility & Resilience - translating to faster and better business outcomes. Over his 25 years of career, Alok has consulted many large corporations, globally.`],
  },
  {
    name: 'Promod Kasinadhuni',
    title: 'Senior Industry Principal - IT Process Consulting Practice, Infosys',
    company: 'Infosys',
    image: 'promod-kasinadhuni.jpg',
    text: [`Promod leads the IT Process Consulting Practice of Infosys across Asia (Middle East, South-East Asia , Japan and India), Canada and Latin America. Promod has extensive consulting experience in the areas of Product-Centric Value Delivery, Enterprise Agility and IT Strategy.`],
  },
  {
    name: 'Sami Skaff',
    title: 'Chief Digital Officer',
    company: 'Al Nabooda Automobiles',
    image: 'SamiSkaff.jpeg',
    text: [``],
  }

];

export const speakers = [
  {
    name: 'Ritu Raj Sharma',
    title: 'VP & Head of SAP Digital, Europe',
    company: 'Infosys',
    image: 'ritu-raj.jpg',
    text: [`VP & Head of SAP Digital, Europe, Infosys`],
  },
  {
    name: 'Joseph Alenchery',
    title: 'SVP & Regional Head, Energy',
    company: 'Infosys',
    image: 'joseph-alenchery.jpg',
    text: [`SVP & Regional Head, Energy, Infosys`],
  },
  {
    name: 'Sami Shaikh',
    title: 'Customer Advisor, AWS for SAP, MENAT',
    company: 'AWS',
    image: 'Sami-Shaikh.jpeg',
    text: [`Customer Advisor, AWS for SAP, MENAT, AWS`],
  },
  {
    name: 'Prashant Gupta',
    title: 'Europe Sales Head - SAP Cloud Transformation',
    company: 'Infosys',
    image: 'Prashant-Gupta.jpg',
    text: [`Europe Sales Head - SAP Cloud Transformation, Infosys`],
  },
  {
    name: 'Satya Prathangi',
    title: 'Principal Specialist - SAP on AWS',
    company: 'AWS',
    image: 'Satya-Prathangi.png',
    text: [`Europe Sales Head - Principal Specialist - SAP on AWS, AWS`],
  },
  {
    name: 'Zafar Ahmed',
    title: 'Global Head of SAP Sales, SURE',
    company: 'Infosys',
    image: 'zafar-ahmed.jpg',
    text: [`Global Head of SAP Sales, SURE, Infosys`],
  },
  {
    name: 'Manal',
    title: 'Customer Sevice and Delivery Executive',
    company: 'SAP EMEA',
    image: 'Manal.jpeg',
    text: [`Customer Sevice and Delivery Executive - SAP EMEA`],
  },
  {
    name: 'Kumar Paramasivam',
    title: 'Vice President',
    company: 'Infosys',
    image: 'Kumar_Paramasivam.jpeg',
    text: [`Customer Sevice and Delivery Executive - Infosys`],
  },
  /* {
    name: 'Ravi Krishnan',
    title: 'Vice President',
    company: 'Infosys',
    image: 'ravi-krishnan.jpg',
    text: [`Vice President, Infosys`],
  }, */

];

export const news = [];
