import React from "react";
import { Container, Row, Col } from "reactstrap";
import './TextGrid.css'
function TextGrid() {
  let pageHeader = React.createRef();
  return (
    <>
      <div
        style={{
          backgroundImage: "url(" + require("assets/images/Asset4.png") + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
        className="py-5"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1
                className="text-left section-title text-900"
                style={{ color: "#373a4a", fontSize: "60px" }}
              >
                <b>Overview</b>
              </h1>
            </Col>
            <Col lg={7} className="align-self-center">
              <h3
                className="text-dark text-400"
                style={{
                  lineHeight: "1.5rem",
                  letterSpacing: "0.5px",
                  fontWeight: 100,
                  textAlign: "justify",
                }}
              >
                In an era of evolving cyber threats, financial institutions must stay ahead by leveraging real-time threat intelligence for proactive defense.
                <br />
                <br />
                SecurityHQ, in collaboration with KT Events, presents an exclusive, high- level roundtable discussion titled “Securing Dubai's Financial Institutions: Turning Threat Intelligence into Actionable Defence”
              </h3>
            </Col>
          </Row>
        </Container>
        {/* <Container>
          <Row className="mt-5">
            {content.map((c) => (
              <Col lg={6} className="align-self-center">
                <Card className="p-3" style={{ background: "#0025c8" }}>
                  <h2 className=" text-700 mb-4" style={{ color: "white" }}>
                    {c.title}
                  </h2>
                  <h3 className="text-white text-400">{c.description}</h3>
                </Card>
              </Col>
            ))}
          </Row>
        </Container> */}
      </div>
      <div
        style={{ background: "rgb(9 149 255)", paddingTop: '80px', paddingBottom: '80px' }}
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1 className="text-center section-title text-900" style={{ color: '#fff' }}>Topics that will be discussed during the event</h1>
            </Col>
            <Col lg={4} className="mb-3">
              <h3 className="styleDiscuss">
                Operationalizing Threat Intelligence for Proactive Defence
              </h3>
            </Col>
            <Col lg={4} className="mb-3">
              <h3 className="styleDiscuss">
                Mitigating Emerging Cyber Risks in the Financial Sector
              </h3>
            </Col>
            <Col lg={4} className="mb-3">
              <h3 className="styleDiscuss">
                Strengthening Financial Ecosystem Security
              </h3>
            </Col>
          </Row>
        </Container>
      </div>

      <div
        style={{ background: "#f5f5f5", paddingTop: '80px', paddingBottom: '80px' }}
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1 className="text-center section-title text-dark text-900" style={{ color: '#000' }}>
                Top 3 challenges that SecurityHQ will be addressing the target segment.
              </h1>
            </Col>
            <Col lg={4} className="mb-3">
              <h3 className="styleChallenge">
                Proactive Defense Against Emerging Threats
              </h3>
            </Col>
            <Col lg={4} className="mb-3">
              <h3 className="styleChallenge">
                Safeguarding Financial Data and Transactions
              </h3>
            </Col>
            <Col lg={4} className="mb-3">
              <h3 className="styleChallenge">
                Managing Third Party Supply Chain Risks
              </h3>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
// const content = [
//   {
//     title: "Banking Cloud Strategies",
//     description:
//       "Explore how leading banks are adopting ‘cloud-first’ and hybrid cloud strategies to enhance agility, scalability, and competitiveness in a dynamic financial landscape. What is the most suitable cloud strategy and solution to create a robust operational infrastructure?",
//   },
//   {
//     title: "Customer-Centric Banking in the Cloud",
//     description:
//       "How are banks using cloud solutions to provide seamless and personalized services, creating deeper customer relationships and responding to the evolving customer expectations?",
//   },
//   {
//     title: "Data-Driven Financial Insights",
//     description:
//       "How can financial institutions capitalize on Cloud4C's and Oracle's expertise in leveraging cloud platforms for data-driven insights, enabling smarter decisions, and enhanced risk management.",
//   },
//   {
//     title: "Security and Compliance in Cloud Banking",
//     description:
//       "We will analyse recommendations regarding cloud security, data protection, and regulatory compliance within the context of Financial Services, ensuring that institutions remain secure while reaping the benefits of the cloud. ",
//   },
// ];
