import React from 'react';
import {
  Container,
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import AnchorLink from 'react-anchor-link-smooth-scroll';

function NavbarDiv({ home }) {
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const [scroll, setScroll] = React.useState(home ? true : false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle('nav-open');
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
        setScroll(false);
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
        setScroll(true);
      }
      if (!home) {
        setScroll(false);
      }
    };

    window.addEventListener('scroll', updateNavbarColor);

    return function cleanup() {
      window.removeEventListener('scroll', updateNavbarColor);
    };
  });

  return (
    <>
      <Navbar
        className={`fixed-top`}
        style={{ minHeight: '40px' }}
        color-on-scroll="300"
        expand="lg"
      >
        <Container fluid>
          <div className="navbar-translate">
            <div>
              <NavbarBrand
                data-placement="bottom"
                /* to="/"
                target="_blank" */
                className="p-0"
                tag={Link}
                onClick={() => window.open('https://www.infosys.com/', '_blank')}
              >
                <img
                  src={require(`assets/images/infosys/info-logo.png`)}
                  className=""
                  width="100%"
                  style={{ maxWidth: '86px' }}
                  alt="logo"
                />
              </NavbarBrand>
              {/* <img
                  src="/logos/poweredByAws.png"
                  alt=""
                  onClick={() => window.open('https://aws.amazon.com/', '_blank')}
                  style={{
                    maxWidth: '77px',
                    height: 'auto',
                    borderLeft: '1px solid #000',
                    marginLeft: '7px',
                    paddingLeft: '7px',
                    cursor: 'pointer',
                  }}
              /> */}
            </div>
            <button
              aria-expanded={navbarCollapse}
              className={classnames('navbar-toggler text-right navbar-toggler', {
                toggled: navbarCollapse,
              })}
              onClick={toggleNavbarCollapse}
            >
              <span className="navbar-toggler-bar bar1 text-white" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse className="justify-content-end fresh-nav" navbar isOpen={navbarCollapse}>
            <Nav navbar>
              <NavItem className="d-flex align-middle">
                {social.map((s) => (
                  <a
                    key={s.name}
                    className={`btn-primary pt-4 px-2`}
                    style={{
                      color: scroll ? '#000000' : '#000000',
                      backgroundColor: 'transparent',
                    }}
                    href={s.link}
                  >
                    <i className={`fa fa-${s.name}`} style={{ fontSize: '1.5rem' }} />
                  </a>
                ))}
              </NavItem>
              {menu.map((link, i) =>
                !link.items ? (
                  <NavItem key={i}>
                    {window.location.pathname === '/' && link.page === '/' ? (
                      <AnchorLink
                        offset="100"
                        href={`#${link.link}`}
                        onClick={toggleNavbarCollapse}
                        className="nav-link navbar-item-custom"
                        style={{ color: scroll ? '#000000' : '#000000' }}
                      >
                        {link.name}
                        <hr className="m-0 mt-1 p-0" align="center" />
                      </AnchorLink>
                    ) : link.page ? (
                      <a
                        href={`/#${link.link}`}
                        className="nav-link navbar-item-custom"
                        style={{ color: scroll ? '#000000' : '#000000' }}
                      >
                        {link.name}
                        <hr className="m-0 mt-1 p-0" align="center" />
                      </a>
                    ) : (
                      <a
                        href={`${link.link}`}
                        className="nav-link navbar-item-custom"
                        style={{ color: scroll ? '#000000' : '#000000' }}
                      >
                        {link.name}
                        <hr className="m-0 mt-1 p-0" align="center" />
                      </a>
                    )}
                  </NavItem>
                ) : (
                  <UncontrolledDropdown nav inNavbar key={i}>
                    <DropdownToggle
                      nav
                      caret
                      className="navbar-item-custom"
                      style={{ color: scroll ? '#000000' : '#000000' }}
                    >
                      {link.name}
                    </DropdownToggle>
                    <DropdownMenu right>
                      {link.items.map((i) => (
                        <DropdownItem
                          href={i.link}
                          key={i.link}
                          className="navbar-item-custom text-400"
                        >
                          {i.name}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )
              )}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarDiv;

const menu = [
  { name: 'Home', link: '/infosys', page: undefined, items: undefined, grid: 1 },
  { name: 'About', link: '/infosys/#about', page: undefined, items: undefined, grid: 1 },
  /* {
    name: 'Overview',
    link: '',
    page: '/',
    items: [
      { name: 'ABOUT', link: '/infosys/#about' },
      { name: 'THE ORGANIZERS', link: '/infosys/overview/the-organiser' },
      { name: 'CONTACT US', link: '/infosys/overview/contact-us' },
    ],
    grid: 1,
  }, */

  /* {
    name: 'Sponsors',
    link: '/infosys/#sponsors',
    page: undefined,
    items: undefined,
    grid: 1,
  }, */
  {
    name: "Speakers",
    link: "/infosys/#speakers",
    page: undefined,
    items: undefined,
    grid: 1,
  },
  {
    name: 'Register',
    link: '/infosys/register',
    page: undefined,
    items: undefined,
    grid: 1,
  },
  /* {
    name: 'Gallery',
    link: '/infosys/Gallery',
    page: undefined,
    items: undefined,
    grid: 1,
  }, */
];

const social = [
  // {
  //   name: "facebook",
  //   link: "https://www.facebook.com/khaleejtimes",
  // },
  // {
  //   name: 'linkedin',
  //   link: 'https://www.linkedin.com/company/khaleejtimes/mycompany/',
  // },
  // {
  //   name: "twitter",
  //   link: "https://twitter.com/khaleejtimes",
  // },
];
