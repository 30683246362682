// reactstrap componentsuaeIndia
import { Container, Col, Row, Button } from "reactstrap";

import React from "react";

function LandingPageHeader({ home }) {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      <div
        style={{
          overflow: "hidden",
          background: "#92d6ff",
          backgroundImage: "url(" + require("assets/images/creative.png") + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          // backgroundImage:
          //   "linear-gradient(106deg, rgba(1,255,255,1) 0%, rgba(0,11,46,1) 59%)",
        }}
        className="section pt-0"
        // ref={myRef}
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col lg={6}>
              <img
                src={require(`assets/images/awardlogowhite.png`)}
                width="100%"
                alt="KhaleejTimes"
              />
            </Col>
            {size && (
              <>
                <Col lg={4} className="text-right align-self-center">
                  <a
                    href="https://business.workl.co/workplace-awards/khaleej-times-uae"
                    target="_BLANK"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require(`assets/images/enter-2025.png`)}
                      width="100%"
                      style={{ maxWidth: 190 }}
                      alt="KhaleejTimes"
                    />
                  </a>
                </Col>
                <Col lg={2} className="d-flex justify-content-end">
                  <div
                    style={{
                      background: "#6648b4",
                      padding: 10,
                      width: 180,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      maxHeight: 250,
                    }}
                  >
                    <h1
                      className="awards-body text-center text-white pt-3"
                      style={{ fontSize: 19 }}
                    >
                      <b> SCAN TO ENTER</b>
                    </h1>
                    <img
                      src={require(`assets/images/Khaleej_Times_Best_Places_to_Work_in_the_UAE (2).png`)}
                      width="100%"
                      alt="KhaleejTimes"
                    />
                  </div>
                </Col>
              </>
            )}
            {!size && (
              <>
                <Col xs={6} className="text-right align-self-center my-5">
                  <a
                    href="https://business.workl.co/workplace-awards/khaleej-times-uae"
                    target="_BLANK"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require(`assets/images/enter-2025.png`)}
                      width="100%"
                      style={{ maxWidth: 190 }}
                      alt="KhaleejTimes"
                    />
                  </a>
                </Col>
                <Col xs={6} className="d-flex justify-content-end my-5">
                  <div
                    style={{
                      background: "#6648b4",
                      padding: 10,
                      width: 180,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      maxHeight: 250,
                    }}
                  >
                    <h1
                      className="awards-body text-center text-white pt-3"
                      style={{ fontSize: 19 }}
                    >
                      <b>SCAN TO ENTER</b>
                    </h1>
                    <img
                      src={require(`assets/images/Khaleej_Times_Best_Places_to_Work_in_the_UAE (2).png`)}
                      width="100%"
                      alt="KhaleejTimes"
                    />
                  </div>
                </Col>
              </>
            )}
          </Row>
          <Row>
            <Col lg={6}>
              <h1 className="awards-title" style={{ fontSize: 30, textAlign: "left" }}>
                Is your organisation the best place to work in the UAE?
              </h1>
              <br />
              <h3 className="awards-body" style={{ fontSize: 26, textAlign: "left" }}>
                Any company, of any size, in any industry sector can enter to be in with a chance of
                being recognised as a Khaleej Times Best Place To Work in the UAE 2025.
                <br />
                <br />
                <b style={{ fontWeight: 700 }}>Entries close on Friday 14<sup>th</sup> November 2025.</b>
                <br />
                <b style={{ color: "#FC7A7A", fontWeight: 700 }}>Don't miss out!</b>
              </h3>
              <br />
              <div className="d-flex">
                <Button
                  block
                  className="btn text-white text-700 mt-3 awards-body mx-2"
                  style={{ backgroundColor: "#6648b4", border: 0 }}
                  size="lg"
                  target="_BLANK"
                  rel="noopener noreferrer"
                  href="https://calendar.app.google/gtXbse8QwX97tgDM9"
                  hrefOld="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ2KG-H3B_6aiG6WaCYTOruGt9LSS36FFCi8qBOXXnTJmAS5rvkPUbyDwipodpS7FD6JKebNYO2k?sid=1544502&rID=17660736"
                >
                  <span className="awards-body">BOOK A DEMO</span>
                </Button>
                <Button
                  block
                  className="btn text-white text-700 mt-3 mx-2"
                  style={{ backgroundColor: "#6648b4", border: 0 }}
                  size="lg"
                  target="_BLANK"
                  rel="noopener noreferrer"
                  href="https://business.workl.co/workplace-awards/khaleej-times-uae"
                >
                  <span className="awards-body">FIND OUT MORE</span>
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default LandingPageHeader;
