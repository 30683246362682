import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import SpeakerNew from 'componentsInfosysNew/SpeakerNew.js';
import { speakersNew, speakers } from '../viewsInfosysNew/content';

function NewGrid() {
    let pageHeader = React.createRef();
    return (
        <>
            <div className="inner" data-parallax={true} ref={pageHeader}>
                {/* <Section01 /> */}
                <Container fluid className="p-0">
                    <Row noGutters className="align-items-center">
                        <Col lg={12}>
                            <div className="sliderDiv">
                                <img
                                    src={require("assets/images/infosys/banner2025.jpg")}
                                    width="100%"
                                    alt="Infosys"
                                />
                                <div className="sliderText">
                                    <h2>Digital Operating Model for an AI-first Enterprise</h2>
                                    <h3>Exclusive Executive Roundtable</h3>
                                    <h5>February 25, 2025</h5>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                {/* <Section02 /> */}
                <section className="aboutSection sectionPadding" id="about">
                    <Container>
                        <Row className="align-items-center">
                            {/* <Col lg={6}>
                                <div className="imgBox">
                                    <img src={require("assets/images/infosys/home-img1.jpg")} className="img-fluid" alt="" />
                                </div>
                            </Col> */}
                            <Col lg={12}>
                                <div className="textBox">
                                    <p>In today's rapidly evolving digital landscape, enterprises face the challenge of realizing and sustaining business value from their multi-year digital transformation programs. Traditional operating models, characterized by fragmented value chains and functional silos, hinder innovation and limit business growth. To stay ahead, organizations must transition to a product-centric, platform-enabled digital operating model that fosters agility, innovation, and efficiency.</p>
                                    <p>This exclusive executive roundtable will bring together top CIOs, CTOs, Chief Digital Officers, and business leaders from diverse industries to discuss how enterprises can embrace an AI-first approach by integrating technology, culture, and strategy into their digital transformation journey.</p>
                                    <Button href="/infosys/register" className="btnInfo" style={{ marginTop: '16px' }}>
                                        Register To Attend
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                {/* <Section03 /> */}
                <section className="whySection sectionPadding">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={12}>
                                <h1 className="sectionTitle text-white">Topics To focus On</h1>
                            </Col>
                            <Col lg={6}>
                                <div className="whyBox">
                                    <h3>Sustaining Business Value</h3>
                                    <p>How can enterprises maximize the value from long-term digital transformation programs?</p>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="whyBox">
                                    <h3>AI-first Foundations</h3>
                                    <p>What strategies help lay a robust digital foundation for an AI-first enterprise?</p>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="whyBox">
                                    <h3>Project to Product Mindset</h3>
                                    <p>How can businesses transition to a product-centric, platform-driven approach?</p>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="whyBox">
                                    <h3>Culture of Innovation</h3>
                                    <p>How can organizations foster a mindset of problem-solving, experimentation, and agility?</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                {/* <Section04 /> */}
                {/* <section className="keySection sectionPadding">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={7}>
                                <div className="textBox">
                                    <h1>Top 3 challenges that Infosys will be addressing the target segment.</h1>
                                    <ul>
                                        <li>Help customers in taking a data backed decision in RISE with SAP adoption.</li>
                                        <li>Help customers in demystifying RISE & GROW</li>
                                        <li>How RISE with AWS option helps customers in faster adoption of native AI/ML services from AWS.</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg={5}>
                                <div className="imgBox">
                                    <img src={require("assets/images/infosys/home-img2.jpg")} className="img-fluid" alt="" />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section> */}
                {/* <Section05 /> */}
                <section className="agendaSection sectionPadding">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={12}>
                                <h1 className="sectionTitle">Agenda</h1>
                            </Col>
                            <Col lg={12}>
                                <div className="mainBox">
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead className="table-dark">
                                                <tr>
                                                    <th width="180">Time</th>
                                                    <th>Session</th>
                                                    <th>Speaker</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>11:00 AM to 11:30 AM</td>
                                                    <td>Registrations and Networking</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>11:30 AM to 11:35 AM</td>
                                                    <td>Welcome Address</td>
                                                    <td>Nikhil Nair, Head of Sales and Partnerships, Khaleej Times</td>
                                                </tr>
                                                <tr>
                                                    <td>11:35 AM to 11:45 AM</td>
                                                    <td>Keynote Address</td>
                                                    <td>Alok Uniyal, Senior Vice President & Head - Process Consulting Practice, Infosys</td>
                                                </tr>
                                                <tr>
                                                    <td>11:45 AM to 11:50 AM</td>
                                                    <td>Icebreaker Session & Delegate Introductions</td>
                                                    <td>Nikhil Nair, Head of Sales and Partnerships, Khaleej Times</td>
                                                </tr>
                                                <tr>
                                                    <td>11:50 AM to 12:05 PM</td>
                                                    <td>Roundtable Discussion</td>
                                                    <td>Moderator: Promod Kasinadhuni, Senior Industry Principal - IT Process Consulting Practice, Infosys</td>
                                                </tr>
                                                <tr>
                                                    <td>12:05 PM to 12:35 PM</td>
                                                    <td>Panel Discussion</td>
                                                    <td>Moderator: Alok Uniyal, Senior Vice President & Head - Process Consulting Practice, Infosys</td>
                                                </tr>
                                                <tr>
                                                    <td>12:35 PM to 12:50 PM</td>
                                                    <td>QnA with Panel and Experience Sharing</td>
                                                    <td>Moderator: Alok Uniyal, Senior Vice President & Head - Process Consulting Practice, Infosys</td>
                                                </tr>
                                                <tr>
                                                    <td>12:50 PM to 12:55 PM</td>
                                                    <td>Discussions Wrap-up</td>
                                                    <td>Moderator: Promod Kasinadhuni, Senior Industry Principal - IT Process Consulting Practice, Infosys</td>
                                                </tr>
                                                <tr>
                                                    <td>12:55 PM to 01:00 PM</td>
                                                    <td>Closing Note</td>
                                                    <td>Nikhil Nair, Head of Sales and Partnerships, Khaleej Times</td>
                                                </tr>
                                                <tr>
                                                    <td>01:00 PM to 02:00 PM</td>
                                                    <td>Networking Lunch</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                {/* <Section06 /> */}
                <section className="speakersSection sectionPadding" id="speakers">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={12}>
                                <h1 className="sectionTitle text-white">Speakers 2025</h1>
                            </Col>
                        </Row>
                        <Row className="align-items-center justify-content-center">
                            <SpeakerNew speakers={speakersNew} />
                        </Row>
                    </Container>
                </section>
                <section className="speakersSection sectionPadding d-none" id="speakers">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={12}>
                                <h1 className="sectionTitle text-white">Past Speakers</h1>
                            </Col>
                        </Row>
                        <Row className="align-items-center justify-content-center">
                            <SpeakerNew speakers={speakers} />
                        </Row>
                    </Container>
                </section>
            </div>
        </>
    );
}

export default NewGrid;
